import * as dayjs from "dayjs";
import { ClientSite, FillRequestReason, JobType } from ".";

export interface JobsFilters {
  PageSize: number;
  PageNumber: number;
  OrderBy: JobsOrderBy
}

export enum JobsOrderBy {
  StartDate = 0,
  CandidateName = 1
}

export interface JobsOpenPoolResult {
  Total: number;
  Jobs: JobOpenPool[];
}

export interface JobOpenPool {
  VacancyId: string;
  JobName: string;
  JobRoleName: string;
  ClientSite: string;
  JobType: number;
  JobStatus: number;
  StartsToday: boolean;
  OpenJobStatus: JobOpenStatus;
  CreatedOn: Date;
  InviteExpires: Date;
  TimeToReply: number;
  RoleId: string;
  Tags: JobOpenTag[];
  Days: JobOpenDay[];
  Invites: JobOpenCandidate[];
  FillRequestReason: FillRequestReason;
  JobDescription: string;
  PositionCount: number;
}

export interface JobOpenCandidate {
  CandidateId: string;
  Name: string;
  PhotoURL: string;
  Status: JobOfferInviteStatus;
  TravelMode: string;
  JourneyTime: number;
  Distance: number;
}

export enum JobOfferInviteStatus {
  Offered	= 0,
  Expired	= 1,
  Declined = 2
}

export interface JobOpenDay {
  DayDateTime: Date;
  DayType: VacancyBookingTimeType
}

export enum VacancyBookingTimeType {
  AllDay = 0,
  AMBooking	= 1,
  PMBooking	= 2
}

export interface JobOpenTag {
  TagId: string;
  Name: string;
  Category: string;
}

export enum JobOpenStatus {
  Offered = 0,
  OfferExpired = 1,
  Declined = 2,
  JobExpired = 3
}

export interface JobPoolResult {
  Total: number;
  Jobs: JobPool[];
}

export interface JobPool {
  Id: string;
  JobName: string;
  ClientSite: ClientSite;
  JobRoleName: string;
  RoleId: string;
  JobType: JobType;
  WorkingToday: boolean;
  Tags: JobTag[];
  Days: JobDay[];
  Declined: JobDeclined[];
  Candidate: JobCandidate;
  ArrivalStatus: JobArrivalStatus;
  VettingStatementUrl: string;
  JobDescription: string;
}

export interface JobTag {
  Name: string;
  CategoryType: number;
}

export interface JobDay {
  Date: Date;
  StartTime: dayjs.Dayjs;
  EndTime: dayjs.Dayjs;
  PayRate: number;
  InvoiceRate: number;
  DayType: VacancyBookingTimeType
}

export interface JobDeclined {
  Id: string;
  Name: string;
  PhotoURL: string;
  Responded: boolean;
}

export interface JobCandidate {
  Id: string;
  Name: string;
  PhotoURL: string;
  ChatChannelId: string;
  Documents: JobCandidateDocument[];  
}

export interface JobCandidateDocument {
  Name: string;
  ApprovalDate: Date;
  ExpiresOn: Date;
  Type: number;
  DocumentType: string;
  DocumentUrl: string;
}

export interface JobArrivalStatus {
  StatusId: ArrivalStatus;
  EstimatedArrival?: Date;
  Longitude?: number;
  Latitude?: number;
  LastKnownLocationDateTime?: Date;
  UpdatedBy?: ArrivalUpdatedByType
}

export interface CandidateLiveLocation {
  CandidateId: string;
  FirstName: string;
  LastName: string;
  PhotoURL: string,
  Longitude: number;
  Latitude: number;
  StatusId: ArrivalStatus;
  EstimatedArrival: Date;
  UpdatedBy: ArrivalUpdatedByType;
  LastUpdatedDateTime: Date;
  JobRoleName: string;
  ClientSiteId: string;
}
export interface TalentPoolVacancyTag {
  vacancyId: string;
  tagId: string;
  tagName: string;
}

export interface TalentPoolCandidateJob {
  id: string;
  candidateId: string;
  title: string;
  jobType: string;
  firstDayDate: Date;
  firstDayType: string;
  area: string;
  daysCount: number;
  tags: TalentPoolVacancyTag[];
}

export enum ArrivalStatus {
  NotWorkingToday = -1,
  GettingReady = 0,
  OnWayAssumed = 1,
  OnWay	= 2,
  RunningLate	= 3,
  ArrivedAssumed	= 4,
  Arrived	= 5,
  CheckedIn	= 6
}

export enum ArrivalUpdatedByType {
  Candidate	= 1,
  Client = 2,
  System = 3,
  GPS = 4
}

export const JobOfferInviteSource = {
  BuildAJob: "BuildAJob",
  TalentPool: "TalentPool",
  BankStaff: "BankStaff",
  TalentCandidateTile: "TalentCandidateTile",
  AddMorePeople: "AddMorePeople",
  ProfileCalendar: "ProfileCalendar",
  TalentPoolWidget: "TalentPoolWidget",
  TimesheetWidget: "TimesheetWidget",
  TalentProfileWidget: "TalentProfileWidget",
  ExtendBookingWidget: "ExtendBookingWidget"
}
