import { EventEmitter, Injectable, Output } from "@angular/core";
import { PageSize } from "@app/interfaces";
import { CalendarPageState } from "@app/interfaces/calendar";
import { SortOption } from "@app/modules/addjobs/components/book-talent/book-talent.component";
import { Observable } from "rxjs";
import { Candidate } from "./candidate.model";
import { Dayjs } from "dayjs";
import * as dayjs from "dayjs";
import { SupplyCandidatesRequest } from "@app//services/supply.service";
import { BookSupplyType } from "@app/modules/addjobs/pages/home/home.component";

export interface RoleGroupSet {
  RoleGroupId: number;
  FavouritesFiltered: number;
  TotalFiltered: number;
  RoleGroupOrder: number;
}

export interface TalentPoolResult {
  ClientHasFavourites: boolean;
  RoleGroupSets: RoleGroupSet[];
  Candidates: Candidate[];
}

@Injectable()
export class TalentList {
    roleGroup: number;
    supplyType: BookSupplyType;
    candidates: Candidate[] = [];
    favouritesFiltered: number = 0;
    totalFiltered: number = 0;
    state: CalendarPageState;
    selectedSort: SortOption;
    favouritesOnly = false;

    // flag to mark talent list as needing refresh
    // when active, used when global filters are 
    // being changed
    refresh: boolean = false;

    // flags used to display loader circles
    poolLoader: boolean = false;
    weekLoader: boolean = false;
    loadingMoreMembers: boolean = false;

    @Output() onCandidateAvailabilityUpdate = new EventEmitter<string>();

    constructor(roleGroup: number, supplyType: BookSupplyType, calendarStartDate: Dayjs = dayjs()) {
      this.roleGroup = roleGroup;
      this.supplyType = supplyType;
      this.state = new CalendarPageState(5, calendarStartDate);
      this.poolPaginationReset();
    }

    updateTalentList(talentPoolResult: TalentPoolResult) {
      let roleGroupSet = talentPoolResult.RoleGroupSets.find(rg => rg.RoleGroupId === this.roleGroup);

      if (!roleGroupSet) return;
      
      if (this.state.poolPagination.page > 0 ) {
        this.candidates = this.candidates.concat(talentPoolResult.Candidates
        .filter(c => c.RoleGroups.includes(this.roleGroup))
        .map(c => new Candidate().deserialize(c)));
      }
      else {
        this.candidates = talentPoolResult.Candidates
        .filter(c => c.RoleGroups.includes(this.roleGroup))
        .map(c => new Candidate().deserialize(c));
      }
      
      this.favouritesFiltered = roleGroupSet.FavouritesFiltered;
      this.totalFiltered = roleGroupSet.TotalFiltered;

      this.setupProfileAndAvailabilityData();

      this.refresh = false;
      this.state.poolPagination.max_items = this.favouritesOnly ? this.favouritesFiltered : this.totalFiltered;
      this.state.poolPagination.item_length = this.candidates.length;
      this.poolLoader = false;
      this.weekLoader = false
    }

    private setupProfileAndAvailabilityData() {
      for (let i=0; i< this.candidates.length; i++) {
        this.onCandidateAvailabilityUpdate.emit(this.candidates[i].Id.toString());

        if (this.roleGroup === 0) {
          let primaryProfile = this.candidates[i].Profiles.find(p => p.IsPrimary);

          if (!primaryProfile) {
            continue;
          }

          let idx = this.candidates[i].Profiles.indexOf(primaryProfile);

          let currentFirstProfile = this.candidates[i].Profiles[0];
          this.candidates[i].Profiles[0] = primaryProfile;
          this.candidates[i].Profiles[idx] = currentFirstProfile;

          this.setCandidatePayRates(this.candidates[i]);

          continue;
        }

        let groupMatchingProfile = this.candidates[i].Profiles.find(p => !!p && p.RoleGroupId === this.roleGroup);

        if (!groupMatchingProfile) {
          return;
        }

        let idx = this.candidates[i].Profiles.indexOf(groupMatchingProfile);

        let currentFirstProfile = this.candidates[i].Profiles[0];
        this.candidates[i].Profiles[0] = groupMatchingProfile;
        this.candidates[i].Profiles[idx] = currentFirstProfile;

        this.setCandidatePayRates(this.candidates[i]);
      }
    }

    setCandidatePayRates(candidate) {
      candidate.DayPayRate = candidate.Profiles[0].DayPayRate;
      candidate.HalfDayPayRate = candidate.Profiles[0].HalfDayPayRate;
    }

    poolPaginationReset() {
      this.state.poolPagination.reset();
      this.state.poolPagination.page_size = PageSize.lg;    
    }
}
