import { Injectable } from '@angular/core';
import { Candidate } from '@app/models';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  private candidateBookingDetailsSubject: Subject<Candidate[]> = new Subject<Candidate[]>;

  // Observable to subscribe to for  updates
  candidateDetails$: Observable<Candidate[]> = this.candidateBookingDetailsSubject.asObservable();

  updateCandidates(candidates: Candidate[]) {
    this.candidateBookingDetailsSubject.next(candidates);
  }
}
