import { Tag } from '@app/models';
import { ClientContact, Client } from '.';

export interface Vacancy {
  Reference: string;
  ClientContact: ClientContact;
  IsDeleted: boolean;
  ReplyTime: number;
  DeleteReasonId: number;
  UId: string;
  JobTitle: string;
  JobRole: string;
  JobDescription: string;
  JobType: JobType;
  Longitude: number;
  Latitude: number;
  Duration: string;
  VacancyDays: VacancyDay[];
  VacancyTags: Tag[];
  ClientSiteId: string;
  BookerName: string;
  ClientContactId: string;
  ClientContactName: string;
  ClientContactPhoto: string;
  ClientId: string;
  Client: Client;
  RoleId: string;
  Rate: number;
  StartDate: Date;
  EndDate: Date;
  DatePosted: Date;
  DateUpdated: Date;
  Salary: string;
  NegociableRate: string;
  InvoiceRate: number;
  InvoiceHalfDayRate: number;
  PayDayRate: number;
  HalfDayPayRate: number;
  ApplicationEnableFor: LongTermEnableType;
  ConsultantId: string;
  ConsultantEmail: string;
  ConsultantFullName: string;
  ConsultantProfileImage: string;
  IsCheckedIn: boolean;
  FillRequestReason: FillRequestReason;
  UsedDateRange: boolean;
  PositionCount: number;
}

export enum JobType {
  All = 0,
  LongTerm = 1,
  DailySupply = 2,
  LongTermInterviewAccepted = 3
}

export interface VacancyDay {
  Id: string;
  VacancyUid: string;
  Date: Date;
  BookingTimeType: VacancyBookingTime;
  StartTime: string;
  DayStatus: VacancyDayStatus;
  ExtraHours: number;
  ExtraHoursRate: number;
  CurrencySymbol: string;
  InvoiceReference: string;
  InvoiceDayRate: number;
  PayDayRate: number;
  Salary: string;
  StandardRateCardId: number;
  CustomRateCardId: number;
  SelectedRateCard: string;
  RatePattern: DayEditPattern;
  WeekDayPattern: number[];
  DeleteReasonId: number;
}

export interface SendOfferWithVacancy {
  Vacancy: Vacancy;
  JobOffer: OfferInvite;
}

export interface OfferInvite {
  Users: UserInvite[];
  Days: number;
  Hours: number;
  Minutes: number;
  JobUId: string;
  InviteSource: string;
}

export interface UserInvite {
  UserId: string;
  CandidateId: string;
  DailyRate: number;
  HalfDayRate: number;
  MatchedProfileId: number;
}

export enum VacancyBookingTime {
  None = -1,
  AllDay = 0,
  AMBooking = 1,
  PMBooking = 2
}

export enum VacancyDayStatus {
  Booked = 0,
  Arrived = 1,
  CheckedIn = 2,
  SignedOff = 3,
  MemberSignedOff = 4
}

export enum LongTermEnableType {
  MatchedMembers = 1,
  DirectInvites = 2,
  AllMembers = 3
}

export enum DayEditPattern {
  ThisDay = 0,
  ThisAndFutureDays = 1,
  CustomPattern = 2
}

export enum FillRequestReason
{
  NoResultsInWebApp = 0,
  FewResultsInWebApp = 1
}
